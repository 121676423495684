'use client'

import { useState } from 'react'
import {
	Dialog,
	DialogBackdrop,
	DialogPanel,
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	Popover,
	PopoverBackdrop,
	PopoverButton,
	PopoverGroup,
	PopoverPanel,
} from '@headlessui/react'
import {
	Bars3Icon,
	XMarkIcon,
	BuildingLibraryIcon,
	BuildingOfficeIcon,
	HomeIcon,
	DocumentCheckIcon,
	NewspaperIcon,
	InformationCircleIcon,
	QuestionMarkCircleIcon,
	DocumentChartBarIcon,
	UserGroupIcon,
	PresentationChartLineIcon,
	BoltIcon,
} from '@heroicons/react/24/outline'
import {
	ChevronDownIcon,
	PhoneIcon,
	PlayCircleIcon,
} from '@heroicons/react/20/solid'

const inspectionServices = [
	{
		name: 'Entreprenad',
		description: 'Entreprenadbesiktningar som ökar lönsamheten ',
		href: '/entreprenad',
		icon: BuildingLibraryIcon,
	},
	{
		name: 'Bostadsrättsföreningar',
		description: 'Säkerställ er fastighet inför framtiden',
		href: '/brf',
		icon: BuildingOfficeIcon,
	},
	{
		name: 'Privat',
		description: 'Vi ser till ditt hus är korrekt och säkert byggt.',
		href: '/privat',
		icon: HomeIcon,
	},
]
const technicalServices = [
	{
		name: 'Teknisk Due Diligence',
		description: 'Identifiera problem tidigt i fastighetsinvesteringen',
		href: '/teknisk-due-diligence',
		icon: DocumentChartBarIcon,
	},
	{
		name: 'Teknisk Fastighetsförvaltning',
		description:
			'Säkerställ långsiktig ekonomisk stabilitet för din bostadsrätt',
		href: 'https://www.dinfastighetsforvaltare.se',
		icon: PresentationChartLineIcon,
	},
	{
		name: 'Återbruk, rivning eller underhållsplan',
		description: 'Hur mår er fastighet egentligen?',
		href: '/underhallsplan',
		icon: DocumentCheckIcon,
	},
]
const moreInformation = [
	{
		name: 'Om oss',
		description: 'Företagets historia och bakgrund.',
		href: '/om_oss',
		icon: UserGroupIcon,
	},
	{
		name: 'Sluta Riv',
		description: 'Framtidens besiktning är här i samarbete med SlutaRiv',
		href: '/slutariv',
		icon: BoltIcon,
	},
	{
		name: 'BM-bloggen',
		description: 'Om bygg-industrin och digitalisering.',
		href: '/information/blog',
		icon: NewspaperIcon,
	},
	{
		name: 'Dokument & information',
		description: 'Om bygg och besiktningsprocessen',
		href: '/information/dokumentation',
		icon: InformationCircleIcon,
	},
	{
		name: 'Frågor & Svar',
		description: 'Vanligt förekommande frågor om besiktningar',
		href: '/information/fragor-och-svar',
		icon: QuestionMarkCircleIcon,
	},
]

export default function Nav() {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

	return (
		<header className='fixed top-0 inset-x-0 z-50 px-4 sm:px-6 bg-white border-b border-stone-300'>
			<nav
				aria-label='Global'
				className='mx-auto flex max-w-7xl items-center justify-between py-6 px-2 font-semibold text-base text-stone-950'>
				<div className='flex lg:flex-1'>
					<a href='/' className='-m-1.5 flex items-center justify-center p-1.5'>
						<img
							src='/logos/bm-logo-solo.svg'
							className='mx-auto size-8 sm:size-10 text-center shadow-2xl shadow-blue-600/40'
							alt='✓'
						/>

						<span className='bm-logo-lowercap pl-3 text-base sm:block sm:text-lg'>
							besiktningsman.se
						</span>
					</a>
				</div>
				<div className='flex lg:hidden'>
					<button
						type='button'
						onClick={() => setMobileMenuOpen(true)}
						className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-stone-700'>
						<span className='sr-only'>Open main menu</span>
						<Bars3Icon aria-hidden='true' className='h-6 w-6' />
					</button>
				</div>
				<PopoverGroup className='hidden lg:flex lg:gap-x-12'>
					{/* inspections */}
					<Popover className='relative'>
						<PopoverBackdrop className='fixed inset-0 bg-black/0' />
						<PopoverButton className='flex items-center gap-x-1'>
							Besiktningstjänster
							<ChevronDownIcon
								aria-hidden='true'
								className='h-5 w-5 flex-none text-stone-400'
							/>
						</PopoverButton>

						<PopoverPanel
							transition
							className='absolute -left-8 top-full z-50 mt-3 w-screen max-w-lg overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-stone-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in'>
							<div className='p-4'>
								{inspectionServices.map((item) => (
									<div
										key={item.name}
										className='group relative flex gap-x-6 rounded-lg p-4  leading-6 hover:bg-stone-50'>
										<div className='mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-stone-50 group-hover:bg-white'>
											<item.icon
												aria-hidden='true'
												className='h-6 w-6 text-stone-600 group-hover:text-indigo-600'
											/>
										</div>
										<div className='flex-auto'>
											<a
												href={item.href}
												className='block font-semibold text-stone-900'>
												{item.name}
												<span className='absolute inset-0' />
											</a>
											<p className='mt-1 text-stone-600'>{item.description}</p>
										</div>
									</div>
								))}
							</div>
							<div className='bg-stone-50 px-8 py-6 border-t border-stone-300'>
								<a href='/fyrstegs-besiktning-vatrum'>
									<div className='flex items-center gap-x-3'>
										<h3 className='text-base font-semibold leading-6 text-stone-900 font-display'>
											4-stegsbesiktning av Våtrum
										</h3>
										<p className='rounded-full bg-blue-600/10 ring-blue-300 ring-1 px-2.5 py-1 text-xs font-semibold text-blue-600 '>
											Nyhet
										</p>
									</div>
									<p className='mt-2  leading-6 text-stone-600 '>
										Våtrumsbesiktning är avgörande för att säkerställa att ditt
										badrum är fritt från fukt- och mögelskador.
									</p>
								</a>
							</div>
						</PopoverPanel>
					</Popover>
					{/* property management */}
					<Popover className='relative'>
						<PopoverBackdrop className='fixed inset-0 bg-black/0' />
						<PopoverButton className='flex items-center gap-x-1  font-semibold leading-6 text-stone-900'>
							Tekniska-tjänster
							<ChevronDownIcon
								aria-hidden='true'
								className='h-5 w-5 flex-none text-stone-400'
							/>
						</PopoverButton>

						<PopoverPanel
							transition
							className='absolute -left-8 top-full z-50 mt-3 w-screen max-w-lg overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-stone-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in'>
							<div className='p-4'>
								{technicalServices.map((item) => (
									<div
										key={item.name}
										className='group relative flex gap-x-6 rounded-lg p-4  leading-6 hover:bg-stone-50'>
										<div className='mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-stone-50 group-hover:bg-white'>
											<item.icon
												aria-hidden='true'
												className='h-6 w-6 text-stone-600 group-hover:text-indigo-600'
											/>
										</div>
										<div className='flex-auto'>
											<a
												href={item.href}
												className='block font-semibold text-stone-900'>
												{item.name}
												<span className='absolute inset-0' />
											</a>
											<p className='mt-1 text-stone-600'>{item.description}</p>
										</div>
									</div>
								))}
							</div>
						</PopoverPanel>
					</Popover>
					{/* about */}
					<Popover className='relative'>
						<PopoverBackdrop className='fixed inset-0 bg-black/0' />
						<PopoverButton className='flex items-center gap-x-1  font-semibold leading-6 text-stone-900'>
							Om oss
							<ChevronDownIcon
								aria-hidden='true'
								className='h-5 w-5 flex-none text-stone-400'
							/>
						</PopoverButton>

						<PopoverPanel
							transition
							className='absolute -left-8 top-full z-50 mt-3 w-screen max-w-lg overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-stone-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in'>
							<div className='p-4'>
								{moreInformation.map((item) => (
									<div
										key={item.name}
										className='group relative flex gap-x-6 rounded-lg p-4  leading-6 hover:bg-stone-50'>
										<div className='mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-stone-50 group-hover:bg-white'>
											<item.icon
												aria-hidden='true'
												className='h-6 w-6 text-stone-600 group-hover:text-indigo-600'
											/>
										</div>
										<div className='flex-auto'>
											<a
												href={item.href}
												className='block font-semibold text-stone-900'>
												{item.name}
												<span className='absolute inset-0' />
											</a>
											<p className='mt-1 text-stone-600'>{item.description}</p>
										</div>
									</div>
								))}
							</div>
						</PopoverPanel>
					</Popover>
				</PopoverGroup>
				<div className='hidden lg:flex lg:flex-1 lg:justify-end'>
					<div className=' '>
						<a href='tel:0101654000' className='inline-flex py-1 px-2'>
							010-165 40 00
						</a>
					</div>
					<div>
						<a href='/kontakta_oss' className='inline-flex py-1 px-2'>
							Kontakta oss
						</a>
					</div>
				</div>
			</nav>
			<Dialog
				open={mobileMenuOpen}
				onClose={setMobileMenuOpen}
				className='lg:hidden'>
				<DialogBackdrop className='fixed inset-0 bg-black/30 backdrop-blur-sm z-30' />
				<DialogPanel className='fixed top-0 h-fit right-0 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-stone-900/10 z-50 rounded-b-2xl shadow-xl text-stone-950'>
					<div className='flex items-center justify-between'>
						<a
							href='/'
							className='-m-1.5 flex items-center justify-center p-1.5'>
							<img
								src='/logos/bm-logo-solo.svg'
								className='mx-auto h-10 w-10 text-center shadow-2xl shadow-blue-600/40'
								alt='✓'
							/>
							<span className='bm-logo-lowercap pl-2 text-stone-700 md:text-2xl'>
								besiktningsman.se
							</span>
						</a>
						<button
							type='button'
							onClick={() => setMobileMenuOpen(false)}
							className='-m-2.5 rounded-md p-2.5 text-stone-700'>
							<span className='sr-only'>Close menu</span>
							<XMarkIcon aria-hidden='true' className='h-6 w-6' />
						</button>
					</div>
					<div className='mt-6 flow-root'>
						<div className='-my-6 divide-y divide-stone-500/10'>
							<div className='p-6 bg-stone-50  ring-1 ring-stone-300  mt-8 space-y-6 text-center rounded-xl'>
								<p className='text-center font-display text-base font-bold'>
									Redo att komma igång?
								</p>
								<a
									href='/kontakta_oss'
									className='-my-2.5 inline-flex items-center justify-center bg-black/90  px-4 py-2 align-middle  font-semibold leading-6 text-stone-50  ring-4 ring-stone-600/20 hover:bg-black/60 sm:text-xs w-full rounded-lg'>
									Kontakta oss
									<span aria-hidden='true' className='pl-2'>
										&rarr;
									</span>
								</a>
								<div>
									<a
										href='tel:0101654000'
										className=' font-semibold leading-6 text-center p-2'>
										Växel 010-165 40 00
									</a>
								</div>
							</div>
							<div className='space-y-2 py-6'>
								<Disclosure as='div' className='-mx-3'>
									<DisclosureButton className='group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-stone-900 hover:bg-stone-50'>
										Besiktningstjänster
										<ChevronDownIcon
											aria-hidden='true'
											className='h-5 w-5 flex-none group-data-[open]:rotate-180'
										/>
									</DisclosureButton>
									<DisclosurePanel className='mt-2 space-y-2'>
										{inspectionServices.map((item) => (
											<DisclosureButton
												key={item.name}
												as='a'
												href={item.href}
												className='block rounded-lg py-2 pl-6 pr-3  font-semibold leading-7 text-stone-900 hover:bg-stone-50'>
												{item.name}
											</DisclosureButton>
										))}
									</DisclosurePanel>
								</Disclosure>

								<Disclosure as='div' className='-mx-3'>
									<DisclosureButton className='group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-stone-900 hover:bg-stone-50'>
										Tekniska tjänster
										<ChevronDownIcon
											aria-hidden='true'
											className='h-5 w-5 flex-none group-data-[open]:rotate-180'
										/>
									</DisclosureButton>
									<DisclosurePanel className='mt-2 space-y-2'>
										{technicalServices.map((item) => (
											<DisclosureButton
												key={item.name}
												as='a'
												href={item.href}
												className='block rounded-lg py-2 pl-6 pr-3  font-semibold leading-7 text-stone-900 hover:bg-stone-50'>
												{item.name}
											</DisclosureButton>
										))}
									</DisclosurePanel>
								</Disclosure>

								<Disclosure as='div' className='-mx-3'>
									<DisclosureButton className='group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-stone-900 hover:bg-stone-50'>
										Om oss
										<ChevronDownIcon
											aria-hidden='true'
											className='h-5 w-5 flex-none group-data-[open]:rotate-180'
										/>
									</DisclosureButton>
									<DisclosurePanel className='mt-2 space-y-2'>
										{moreInformation.map((item) => (
											<DisclosureButton
												key={item.name}
												as='a'
												href={item.href}
												className='block rounded-lg py-2 pl-6 pr-3  font-semibold leading-7 text-stone-900 hover:bg-stone-50'>
												{item.name}
											</DisclosureButton>
										))}
									</DisclosurePanel>
								</Disclosure>
							</div>
						</div>
					</div>
				</DialogPanel>
			</Dialog>
		</header>
	)
}
